import React from "react"
import styled, { ThemeProvider } from "styled-components"

import ThemeContext from "Stores/themeContext"
import useTheme from "Hooks/useTheme"
import useSiteMetadata from "Hooks/useSiteMetadata"
import NavBar from "Components/navBar/navBar"
import styledTheme from "Styles/styledTheme"
import GlobalStyle from "Styles/globalStyle"
import packageJSON from "../../package.json"

const { name, homepage } = packageJSON

const Layout: React.FC = ({ children }) => {
  const { theme, themeToggler } = useTheme()
  const { title, author } = useSiteMetadata()
  const copyrightStr = `© Made by ${author}`

  return (
    <ThemeProvider theme={styledTheme}>
      <ThemeContext.Provider value={theme}>
        <GlobalStyle />
        <Container>
          <NavBar title={title} themeToggler={themeToggler} />
          {children}
        </Container>

        <ContainerGrid>
          <ContentBox>
            <Content1>
              <FooterTitle>📫 New Key Models in your inbox</FooterTitle>
              Join the newsletter to get new models and announcements the moment they are shared:
              <br></br><br></br>
              <Centernewsletter>
                <iframe height="100" align="middle" border-radius="20" src="https://keymodels.substack.com/embed"></iframe>
              </Centernewsletter>
            </Content1>
            <Content2>
              <FooterTitle>ℹ️ About</FooterTitle>
              A set of thinking tools and frameworks to assist you in solving problems, making decisions, and understanding systems.<br></br>
              <br></br>
              <a href="https://www.buymeacoffee.com/keymodels"> Buy me a coffee to support this project!</a> and <a href="/about">Click here</a> if you want to learn more about this tool!
            </Content2>
          </ContentBox>
        </ContainerGrid>

        <Footer role="contentinfo">
          <FooterContainerGrid>
            <FooterContentBox>
              <FooterContent1>
                <Copyright aria-label="Copyright">
                  {new Date().getFullYear()} {copyrightStr}
                </Copyright>
              </FooterContent1>
              <FooterContent2>
                <a href="/cookiespolicy"><u>Cookies</u></a><br></br>
                <a href="/privacypolicy"><u>Privacy Policy</u></a>
              </FooterContent2>
            </FooterContentBox>
          </FooterContainerGrid>
        </Footer>
      </ThemeContext.Provider>
    </ThemeProvider>
  )
}

const ContainerGrid = styled.div`
          display: grid;
          justify-content: space-evenly;
          box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 60%), 0 1px rgb(0 0 0 / 60%);
          grid-template-areas:
          "content content"
         `

const ContentBox = styled.div`
          display: flex;
          gap: 2rem;
          flex-wrap: wrap;
          padding: 0.25rem;
          margin-top: 3rem;
          margin-right: 3rem;
          margin-bottom: 3rem;
          margin-left: 3rem;
          align-items: center;
          grid-area: content;
          justify-content: center;
          @media (max-width: 550px) {
            flex-direction: column;
          }
          `

const Content1 = styled.div`
          font-size: 20px;
          padding: 1rem;
          width: 550px;
          height: 180px;
          text-align: left;
          font-family: Inter, Helvetica, Arial, sans-serif;
          `

const Content2 = styled(Content1)``

const FooterTitle = styled.h2`
          font-size: 1.5rem;
          font-weight: var(--font-weight-extra-bold);
          margin-bottom: var(--sizing-md);
          `
const Centernewsletter = styled.h2`
        text-align: center;
          `

const FooterContainerGrid = styled.div`
         display: grid;
         background-color: var(--color-gray-1);
         grid-template-areas:
         "content content"
        `

const FooterContentBox = styled.div`
          display: flex;
          grid-area: content;
          `

const FooterContent1 = styled.div`
          font-size: 20px;
          padding: 1rem;
          // width: 100%;
          height: 100%;
          text-align: left;
          font-family: Inter, Helvetica, Arial, sans-serif;
          `

const FooterContent2 = styled.div`
          font-size: 14px;
          padding: 1rem;
          // width: 100%;
          height: 100%;
          text-align: left;
          margin-left: auto; 
          margin-right: 70px;
          font-family: Inter, Helvetica, Arial, sans-serif;
          @media (max-width: 550px) {
            margin-right: 0px;
          }
`
const Container = styled.div`
          width: 100%;
          height: 100%;
          min-height: calc(100vh - var(--footer-height));
          background-color: var(--color-post-background);
          `

const Footer = styled.footer`
          text-align: left;
          justify-content: center;
          align-items: center;
          height: var(--footer-height);
          background-color: var(--color-gray-1);
          `

const Copyright = styled.span`
          font-size: var(--text-sm);
          font-weight: var(--font-weight-regular);
          color: var(--color-gray-6);
          `

const RepoLink = styled.a`
          padding-top: 30px;
          padding-bottom: 30px;
          color: var(--color-blue);
          &:hover {
            text - decoration: underline;
  }
          `

export default Layout
